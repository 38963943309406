export interface CheckoutDetails {
  type: string;
  data: object;
  locale: string;
  browser_info: BrowserInfo;
}

export interface SubmitCheckoutResult {
  action: string;
  action_data: object;
}

export interface CreateCheckoutResult {
  action: string;
  action_data: object;
}

export interface PaymentComponent {
  unmount: () => void;
}

export type SubmitCheckoutDetailsFunction = (details: CheckoutDetails) => Promise<SubmitCheckoutResult>;

export type SelectorOrElement = string | HTMLElement;

export enum Environment {
  production = 'live',
  development = 'test',
}

export interface BrowserInfo {
  color_depth: number;
  java_enabled: boolean;
  javascript_enabled: boolean;
  language: string;
  screen_height: number;
  screen_width: number;
  time_zone_offset: number;
  user_agent: string;
}
