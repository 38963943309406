export interface RedirectAction {
  method: string;
  url: string;
  data: { [key: string]: string } | null;
}
export const handlePostRedirect = (action: RedirectAction) => {
  const x = document.createElement('FORM') as HTMLFormElement;
  x.method = action.method;
  x.action = action.url;
  const actionData = action.data || {};

  Object.keys(actionData).forEach((item) => {
    const i = document.createElement('input');
    i.type = 'hidden';
    i.name = item;
    i.value = actionData[item];
    x.appendChild(i);
  });
  document.body.appendChild(x);
  x.submit();
};

export const handleGetRedirect = (action: RedirectAction) => {
  document.location.href = action.url;
};
