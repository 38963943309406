import { handleGetRedirect, handlePostRedirect, RedirectAction } from './redirects';
import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import { PaymentData } from '@adyen/adyen-web/dist/types/components/types';
import {
  CheckoutDetails,
  CreateCheckoutResult,
  Environment,
  PaymentComponent,
  SelectorOrElement,
  SubmitCheckoutDetailsFunction,
} from './types';
import AdyenCheckout from '@adyen/adyen-web';
import { PaymentMethodsConfiguration } from '@adyen/adyen-web/dist/types/core/types';
import { CustomTranslations } from '@adyen/adyen-web/dist/types/language/types';

const supportedLocales = ['fi-FI', 'sv-SE', 'en-EN'];

interface AdyenActionData {
  clientKey: string;
  paymentMethodType: string;
  paymentMethodDetails: any;
  environment: string | null;
}

const sendAdyenDetails = async (
  submitCheckoutDetailsFunction: SubmitCheckoutDetailsFunction,
  payment: InstanceType<PaymentMethods['redirect']>,
  paymentMethodType: string,
  locale: string,
) => {
  const paymentData = payment.data as PaymentData;
  const browserInfo = {
    color_depth: window.screen.colorDepth,
    java_enabled: false,
    javascript_enabled: true,
    language: navigator.language,
    screen_height: window.screen.height,
    screen_width: window.screen.width,
    time_zone_offset: new Date().getTimezoneOffset(),
    user_agent: navigator.userAgent,
  };
  const details: CheckoutDetails = {
    type: paymentMethodType,
    data: paymentData.paymentMethod,
    browser_info: browserInfo,
    locale,
  };
  const result = await submitCheckoutDetailsFunction(details);
  const actionData = result.action_data;

  switch (result.action) {
    case 'redirect': {
      const redirectAction = actionData as RedirectAction;
      switch (redirectAction.method) {
        case 'POST':
          return handlePostRedirect(redirectAction);
        case 'GET':
          return handleGetRedirect(redirectAction);
        default:
          throw Error(`Unknown redirect method: ${redirectAction.method}`);
      }
    }
    default:
      throw Error(`Unknown action: ${result.action}`);
  }
};

const getAdyenEnvironment = (environment: Environment) => {
  switch (environment) {
    case Environment.production:
      return 'live';
    case Environment.development:
      return 'test';
  }
  throw new Error('Unknown environment: ' + environment);
};

const setupAdyen = async (
  selector: SelectorOrElement,
  checkoutDetailsFunction: SubmitCheckoutDetailsFunction,
  actionData: AdyenActionData,
  locale: string,
  paymentMethodsConfigurationOverride?: PaymentMethodsConfiguration,
  environment: Environment = Environment.development,
  translations?: CustomTranslations,
) => {
  const { clientKey, paymentMethodType, paymentMethodDetails } = actionData;

  // optional payment method configuration
  // eg. for 'card' https://docs.adyen.com/payment-methods/cards/web-component
  const paymentMethodsConfiguration = paymentMethodsConfigurationOverride
    ? paymentMethodsConfigurationOverride
    : {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
        },
      };

  const configuration = {
    environment: getAdyenEnvironment(environment),
    clientKey,
    paymentMethodsConfiguration,
    locale,
    translations,
    analytics: {
      enabled: false,
    },
    paymentMethodsResponse: { paymentMethods: [paymentMethodDetails] },
  };
  const checkout = await AdyenCheckout(configuration);
  const payment = checkout
    .create(paymentMethodType, {
      showPayButton: true,
      onSubmit: (state: any) => {
        if (state.isValid) {
          sendAdyenDetails(checkoutDetailsFunction, payment, paymentMethodType, locale).catch((reason: any) => {
            payment.setElementStatus('ready');
          });
        }
      },
    })
    .mount(selector);
  return payment;
};

/**
 * Setup payment component
 *
 * @param selector Selector or Element in which the payment component is to be embedded
 * @param locale language and country code for payment component. Currently supported 'fi-FI', 'sv-SE', 'en-EN'
 * @param createCheckoutResult Result blob from create-checkout operation
 * @param submitCheckoutDetailsFunction Function to receive CheckoutDetails to be submitted to Checkout API
 * @param paymentMethodsConfigurationOverride Override for payment methods configuration (leave empty for production)
 * @param environment Environment (development or production)
 * @param translations Custom translations (used to replace out-of-the-box text in payment component)
 */
export const setupPaymentComponent = async (
  selector: SelectorOrElement,
  locale: string,
  createCheckoutResult: CreateCheckoutResult,
  submitCheckoutDetailsFunction: SubmitCheckoutDetailsFunction,
  paymentMethodsConfigurationOverride?: object,
  environment: Environment = Environment.development,
  translations?: CustomTranslations,
): Promise<PaymentComponent> => {
  if (supportedLocales.indexOf(locale) === -1) throw Error(`Unsupported locale: ${locale}`);

  const action = createCheckoutResult.action;
  const actionData = createCheckoutResult.action_data;
  switch (action) {
    case 'adyen_sdk': {
      const adyenActionData = actionData as AdyenActionData;
      return await setupAdyen(
        selector,
        submitCheckoutDetailsFunction,
        adyenActionData,
        locale,
        paymentMethodsConfigurationOverride,
        environment,
        translations,
      );
    }
    default:
      throw Error(`Unknown action: ${action}`);
  }
};
